import { zodResolver } from '@hookform/resolvers/zod';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { COLORS } from 'packages/constants';
import {
  Button,
  ButtonLink,
  ButtonType,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  faChevronDown,
  faMagnifyingGlass,
  faSpinnerThird,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Header,
  Icon,
  Input,
  Span,
} from 'packages/elements';
import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';

import AccountAvatar from '@/components/PageTemplate/AccountAvatar';
import { useCurrentUser } from '@/contexts/currentUser';
import { CurrentOrg, OrgAccount, useOrgs } from '@/contexts/organizations';
import {
  accountsLoginUrl,
  accountsLogoutUrl,
  accountsSettingsUrl,
  accountsUrl,
  MARKETING_TIER_PRICING_URL,
  UPBOUND_DOCS_PUBLISH_URL,
  UPBOUND_DOCS_URL,
  UPBOUND_REGISTER_URL_A,
} from '@/utils/constants/urls';
import { canAccessLTSVersions } from '@/utils/helpers/organizations';
import { updateQueryParam, withQueryParams } from '@/utils/helpers/params';
import useParams from '@/utils/hooks/useParams';

import UpboundLogoAnchor from './UpboundLogoAnchor';

const PackageSearchForm: React.FC = () => {
  const router = useRouter();
  const params = useParams(router);
  const packageType: RouteParams.PackageType = 'providers';

  const FormSchema = z.object({
    search: z.string(),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      search: '',
    },
  });

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    if (!data.search) {
      return;
    }

    router.push(withQueryParams(`/${packageType}`, updateQueryParam(decodeURIComponent(data.search), params)));
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex flex-col grow ">
        <FormField
          control={form.control}
          name="search"
          render={({ field }) => (
            <FormItem className="flex flex-row justify-center relative">
              <Icon fontSize={12} icon={faMagnifyingGlass} className="text-neutral-500 absolute left-3 top-2" />
              <FormControl className="!m-0 pl-8">
                <Input
                  placeholder="Search the Upbound Marketplace"
                  {...field}
                  className="bg-neutral-1000 border-neutral-500 text-neutral-0 h-[34px]"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
};

const NavLoadingSpinner: React.FC = () => {
  return (
    <div className="flex items-center w-[40px] justify-center">
      <Icon icon={faSpinnerThird} color={COLORS.Neutral[0]} spin speed="fast" />
    </div>
  );
};

const NavButton: React.FC<{ btnType?: keyof typeof ButtonType } & PropsWithChildren> = ({
  btnType = 'Primary',
  children,
}) => {
  return (
    <Button btnType={btnType} noWrap btnSize="Small">
      {children}
    </Button>
  );
};

const BrowsePackagesDropdown: React.FC = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <NavButton btnType="Transparent">
          Browse Packages <Icon icon={faChevronDown} color={COLORS.Neutral[0]} className="ml-2 min-w-3"></Icon>
        </NavButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-[180px]">
        <DropdownMenuLabel>Package Types</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link href="/providers">Providers</Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link href="/configurations">Configurations</Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link href="/functions">Functions</Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const OrgDropdown: React.FC<{
  orgs: OrgAccount[];
  currentOrg: CurrentOrg;
  setCurrentOrg: Dispatch<SetStateAction<CurrentOrg>>;
}> = ({ orgs, currentOrg, setCurrentOrg }) => {
  const router = useRouter();

  const navWidth = twMerge('flex flex-row gap-2', currentOrg ? ' w-[110px]' : ' w-[120px]');

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <NavButton btnType="TransparentOutline">
          <div className={navWidth}>
            {currentOrg && (
              <AccountAvatar width="18px" height="18px" type="OrgAccount" organization={currentOrg.organization} />
            )}
            <div className="overflow-hidden text-ellipsis"> {currentOrg?.organization.name || 'My Organizations'}</div>
            <Icon icon={faChevronDown} color={COLORS.Neutral[0]} className="ml-auto min-w-3"></Icon>
          </div>
        </NavButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-[200px]">
        {orgs.length > 0 && (
          <>
            <DropdownMenuLabel>My Organizations</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {orgs.map((org, index) => {
              return (
                <DropdownMenuItem
                  key={index}
                  onClick={() => {
                    setCurrentOrg(org);
                  }}
                >
                  <AccountAvatar width="18px" height="18px" mr="2" type="OrgAccount" organization={org.organization} />
                  {org.organization.name}
                </DropdownMenuItem>
              );
            })}
            <DropdownMenuSeparator />
          </>
        )}

        <DropdownMenuItem
          onClick={() => {
            router.push(accountsUrl('/selectOrg'));
          }}
        >
          Create Organization
        </DropdownMenuItem>

        {!!currentOrg && (
          <DropdownMenuItem
            onClick={() => {
              router.push(accountsUrl(`/o/${currentOrg.organization.name}/profile`));
            }}
          >
            Manage
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const AccountDropdown: React.FC = () => {
  const router = useRouter();
  const { currentUser } = useCurrentUser();

  if (!currentUser) {
    return null;
  }

  const { firstName, lastName, email } = currentUser;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <NavButton btnType="TransparentOutline">
          My Account <Icon icon={faChevronDown} color={COLORS.Neutral[0]} className="ml-2 min-w-3"></Icon>
        </NavButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-[180px] mr-2">
        <DropdownMenuLabel>
          <Header type="h6">
            {firstName} {lastName}
          </Header>
          <Span textSize="Size4">{email}</Span>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link href={accountsSettingsUrl(router.asPath)}>Manage Account</Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <Link href={accountsLogoutUrl(router.asPath)}>Sign Out</Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const NavButtonsAnonUser: React.FC = () => {
  const router = useRouter();

  return (
    <>
      <NavButton btnType="Transparent">
        <Link href={UPBOUND_DOCS_PUBLISH_URL}>Publish</Link>
      </NavButton>
      <NavButton btnType="Transparent">
        <Link href={UPBOUND_DOCS_URL}>Learn More</Link>
      </NavButton>
      <NavButton btnType="TransparentOutline">
        <Link href={accountsLoginUrl(router.asPath)}>Sign In</Link>
      </NavButton>
      <NavButton>
        <Link href={UPBOUND_REGISTER_URL_A}>Sign Up Free</Link>
      </NavButton>
    </>
  );
};

const NavButtonsSignedInUser: React.FC = () => {
  const {
    orgState: { orgs, fetchStage },
    currentOrg,
    setCurrentOrg,
  } = useOrgs();

  if (fetchStage === 'loading') {
    return <NavLoadingSpinner />;
  }

  return (
    <>
      {!!currentOrg?.organization && (
        <NavButton btnType="Transparent">
          <Link href={`/account/${currentOrg.organization.name}`}>My Repositories</Link>
        </NavButton>
      )}
      <OrgDropdown orgs={orgs} currentOrg={currentOrg} setCurrentOrg={setCurrentOrg} />
      <AccountDropdown />
      {(!currentOrg || !canAccessLTSVersions(currentOrg)) && (
        <ButtonLink btnType="Primary" href={MARKETING_TIER_PRICING_URL} noWrap btnSize="Small">
          Upgrade Plan
        </ButtonLink>
      )}
    </>
  );
};

export const PageHeader: React.FC = () => {
  const { currentUser, fetchStage } = useCurrentUser();

  const userDataLoading = fetchStage === 'loading';
  const isLoggedIn = !!currentUser;

  return (
    <div className="flex flex-row justify-between bg-neutral-1000 w-full h-[60px] items-center px-6 gap-2">
      <div className="lg:basis-64 xl:basis-80">
        <UpboundLogoAnchor />
      </div>

      <div className="lg:w-1/4 xl:w-1/3">
        <PackageSearchForm />
      </div>

      <div className="flex flex-row gap-2">
        <BrowsePackagesDropdown />

        {userDataLoading && <NavLoadingSpinner />}

        {!userDataLoading && !isLoggedIn && <NavButtonsAnonUser />}
        {!userDataLoading && isLoggedIn && <NavButtonsSignedInUser />}
      </div>
    </div>
  );
};
