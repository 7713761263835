import { Box, LinkProps, useMultiStyleConfig } from '@chakra-ui/react';

import RouteLink from '@/components/RouteLink';
import UpboundLogoIcon from '@/icons/UpboundLogoIcon';

const UpboundLogoAnchor: React.FC<
  Omit<LinkProps, 'href'> & { size?: 'sm' | 'md' | 'lg'; variant?: 'black' | 'purple' }
> = ({ variant = 'black', size = 'lg', ...props }) => {
  const styles = useMultiStyleConfig('UpboundLogoAnchor', { variant, size });
  return (
    <RouteLink
      to="rootIndex"
      display="flex"
      justifyContent="flex-start"
      alignItems="flex-end"
      _hover={{ opacity: 0.7 }}
      maxWidth="308px"
      overflow="hidden"
      prefetch={false}
      {...props}
    >
      <UpboundLogoIcon sx={styles.icon} />
      <Box as="span" sx={styles.marketplace}>
        Marketplace
      </Box>
    </RouteLink>
  );
};

export default UpboundLogoAnchor;
