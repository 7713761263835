import { chakra, FormControl, FormControlProps, Input, Link, useMultiStyleConfig } from '@chakra-ui/react';

import CircledCloseIcon from '@/icons/CircledCloseIcon';
import MagnifierIcon from '@/icons/MagnifierIcon';
import useQueryForm from '@/utils/hooks/useQueryForm';

const PackageSearchForm: React.FC<
  Omit<FormControlProps, 'defaultValue' | 'onSubmit'> & {
    size: 'small' | 'large';
    variant?: 'mobile' | 'desktop';
    visible?: boolean;
    onSubmit: (value: string) => void;
    onClear?: () => void;
    query?: string | null;
  }
> = ({ visible = true, size, variant, onSubmit, onClear, query = null, ...props }) => {
  const styles = useMultiStyleConfig('PackageSearchForm', { size, variant });
  const { value, handleSubmit, handleChange, handleClear } = useQueryForm({ onSubmit, onClear, query });

  if (!visible) {
    return null;
  }

  return (
    <FormControl {...props} as="form" role="group" onSubmit={handleSubmit} position="relative" sx={styles.form}>
      <Input value={value} onChange={handleChange} placeholder="Search Marketplace" sx={styles.input} role="input" />
      <MagnifierIcon sx={styles.icon} />
      {query && onClear && (
        <Link role="clear" sx={styles.clear} onClick={handleClear}>
          <CircledCloseIcon name="close" focusable={false} />
        </Link>
      )}
    </FormControl>
  );
};

export default chakra(PackageSearchForm);
