import { Icon, IconProps } from '@chakra-ui/react';

const MagnifierIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d={`M22.635 20.166 17.414 14.944c1.187-1.763 1.773-3.965 1.46-6.312C18.339 4.64 15.067 1.392 11.071.886
        5.131.134 .135 5.13.887 11.071c.506 3.997 3.755 7.272 7.747 7.804 2.347.314 4.55-.272 6.312-1.46l5.221
        5.221c.681.681 1.786.681 2.468 0 .681-.682.681-1.79 0-2.471zM4.262 9.884c0-3.079 2.505-5.583 5.583-5.583s5.583
        2.505 5.583 5.583-2.505 5.583-5.583 5.583-5.583-2.504-5.583-5.583z`}
      />
    </Icon>
  );
};

export default MagnifierIcon;
