import { BoxProps } from '@chakra-ui/react';
import { RefObject } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { PageFooter as DevExPageFooter, PageHeader as DevExPageHeader } from '@/redesign/PageTemplate';

import PageContent from './PageTemplate/PageContent';
import PageFooter from './PageTemplate/PageFooter';
import PageHeader from './PageTemplate/PageHeader';
import PageTitle from './PageTemplate/PageTitle';

const PageTemplate: React.FC<
  BoxProps & {
    title?: string;
    isSearchVisible?: boolean;
    mobileRef?: RefObject<HTMLDivElement>;
    packageType?: RouteParams.PackageType;
    noFooterPaddingTop?: boolean;
    description?: string | null;
    image?: string | null;
    devExRedesign?: boolean;
  }
> = ({
  children,
  title,
  isSearchVisible = true,
  mobileRef,
  packageType,
  noFooterPaddingTop,
  description,
  image,
  devExRedesign = false,
  ...props
}) => {
  // TODO: The goal eventually is to replace all of `PageTemplate` with the redesigned header and footer.
  // When we flip the switch and migrate all users to the new DevEx redesign, we can remove the conditional logic

  return (
    <ErrorBoundary fallback={<div>Oh no!</div>}>
      <div className="flex flex-col justify-between h-full">
        <PageTitle title={title} description={description} image={image} />
        <PageContent {...props}>
          {devExRedesign ? (
            <DevExPageHeader />
          ) : (
            <PageHeader isSearchVisible={isSearchVisible} mobileRef={mobileRef} packageType={packageType} />
          )}
          {children}
        </PageContent>
        {devExRedesign ? <DevExPageFooter /> : <PageFooter noPaddingTop={noFooterPaddingTop} />}
      </div>
    </ErrorBoundary>
  );
};

export default PageTemplate;
