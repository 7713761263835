import { Icon, IconProps } from '@chakra-ui/react';

const CircledCloseIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d={`M1.418 12C1.418 6.155 6.155 1.418 12 1.418s10.582 4.737 10.582 10.582-4.737 10.582-10.582
        10.582S1.418 17.845 1.418 12zm7.234-1.98 1.947 1.943L8.652 13.943c-.384.389-.384 1.017 0 1.368.389
        .422 1.017.422 1.368 0l1.943-1.91 1.98 1.91c.389.422 1.017.422 1.368 0 .422-.351.422-.98 0-1.368l-1.91-1.98
        1.91-1.943c.422-.351.422-.98 0-1.368-.351-.384-.98-.384-1.368
        0l-1.98 1.947-1.943-1.947c-.351-.384-.98-.384-1.368 0-.384.389-.384 1.017 0 1.368z`}
      />
    </Icon>
  );
};

export default CircledCloseIcon;
