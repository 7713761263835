import Link from 'next/link';
import { COLORS } from 'packages/constants';
import { Span } from 'packages/elements';

import UpboundLogoIcon from '@/icons/UpboundLogoIcon';

const UpboundLogoAnchor: React.FC<{ variant?: 'light' | 'dark' }> = ({ variant = 'light' }) => {
  // TODO: Update Icon to not use Chakra

  const logoColor = variant === 'light' ? 'white.0' : 'black.0';
  const textColor = variant === 'light' ? COLORS.Purple[300] : COLORS.Neutral[800];

  return (
    <Link href="/" className="flex flex-row items-end">
      <UpboundLogoIcon
        sx={{
          color: logoColor,
          width: '95px',
          height: '24px',
        }}
      />
      <Span textSize="Size6" color={textColor} className="ml-1" textWeight="Heavy">
        Marketplace
      </Span>
    </Link>
  );
};

export default UpboundLogoAnchor;
