import { Icon, IconProps } from '@chakra-ui/react';

const SettingsIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 15 15">
      <path
        fill="currentColor"
        d="M14.061 5.099a.603.603 0 0 1-.172.66l-1.184 1.059a5.108 5.108 0 0 1 0 1.365l1.184 1.058c.185.17 .262.43 .172.66-.12.32-.265.63-.43.922l-.128.218a7.08 7.08 0 0 1-.604.84 .633.633 0 0 1-.67.183l-1.523-.478a6.01 6.01 0 0 1-1.203.685l-.342 1.534c-.055.242-.246.414-.498.478a7.27 7.27 0 0 1-2.324 0c-.252-.064-.443-.236-.498-.478l-.342-1.534a5.398 5.398 0 0 1-1.203-.685l-1.522.478a.631.631 0 0 1-.67-.182A7.05 7.05 0 0 1 1.501 11.041l-.13-.218a6.645 6.645 0 0 1-.432-.921.603 .603 0 0 1 .174-.661L2.297 8.183a5.109 5.109 0 0 1 0-1.365L1.114 5.759a.602.602 0 0 1-.174-.66c.121-.32.266-.63.432-.922l.127-.217c.181-.296.383-.575.605-.84a.628.628 0 0 1 .67-.183l1.522.477a5.282 5.282 0 0 1 1.203-.684l.342-1.534a.615.615 0 0 1 .498-.479 7.161 7.161 0 0 1 2.324 0 .615.615 0 0 1 .498.479l.342 1.534a5.86 5.86 0 0 1 1.203.684l1.523-.477a.63.63 0 0 1 .67.183c.221.265 .423.544 .604.84l.128.217c.165.293 .31.602 .43.922zM7.501 9.649a2.173 2.173 0 0 0 2.187-2.173c0-1.163-.978-2.15-2.187-2.15-1.209 0-2.188.987-2.188 2.15 0 1.212.98 2.173 2.188 2.173z"
      />
    </Icon>
  );
};

export default SettingsIcon;
