import { COLORS } from 'packages/constants';
import { Button, faChevronDown, Icon, Span } from 'packages/elements';
import { Dispatch, SetStateAction } from 'react';

import { RegistryPackage } from '@/graphql/upbound-graphql';

export const PackageVersionButton: React.FC<{
  data: RegistryPackage;
  setIsVersionsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ data, setIsVersionsDrawerOpen }) => {
  if (!data || !data.versions || data.versions.length === 0) {
    return null;
  }

  const { versions } = data;
  const [firstVersion] = versions;
  const selectedVersion = data.version || firstVersion;

  return (
    <Button
      btnType="Secondary"
      onClick={() => {
        setIsVersionsDrawerOpen(true);
      }}
    >
      <Span textSize="Size5" className="!font-bold whitespace-nowrap mr-1">
        Version:
      </Span>
      <Span textSize="Size5" className="whitespace-nowrap">
        {selectedVersion}
      </Span>
      <Icon icon={faChevronDown} ml="5px" color={COLORS.Neutral[900]} fontSize="12px" />
    </Button>
  );
};
