import { Image, ImageProps } from '@chakra-ui/react';

import * as Avatars from '@/assets/avatars';
import { Organization, User } from '@/graphql/upbound-graphql';

const imageArray = Object.values(Avatars);

const getAvatarComponent = (val: string | undefined) => {
  if (!val) {
    return imageArray[0];
  }

  let charNum = val.charCodeAt(0) - 96;
  let index = 0;

  if (charNum < 0) {
    charNum *= -1;
  }

  while (charNum || index > imageArray.length) {
    index += charNum % 10;
    charNum = Math.floor(charNum / 10);

    if (!charNum && index > imageArray.length) {
      charNum = index - imageArray.length;
      index = 0;
    }
  }

  if (index >= imageArray.length) {
    index -= imageArray.length;
  }

  return imageArray[index];
};

const propCheck = (name1: string | undefined, name2: string | undefined, last = false) => {
  if (name1) {
    return name1;
  } else if (name2) {
    return name2.split(' ')[last ? 1 : 0];
  } else {
    return '';
  }
};

const AccountAvatar: React.FC<
  ImageProps & {
    memberName?: string | null;
    organization?: Pick<Organization, 'id' | 'displayName'> | null;
    user?: Pick<User, 'username' | 'email'> | null;
    type?: 'OrgAccount' | 'UserAccount' | 'orgMember' | 'teamMember';
  }
> = ({ memberName = null, organization = null, type = 'UserAccount', user = null, ...props }) => {
  let valueToHash = ' ';
  const username: string = type === 'OrgAccount' && user !== null ? user.username : '';
  const orgName: string =
    type === 'OrgAccount' && organization !== null ? propCheck(organization.displayName, undefined) : '';

  if (type === 'UserAccount' && user !== null && (username || user.email)) {
    valueToHash = username ? username.slice(0, 1).toUpperCase() : user.email && user.email.slice(0, 1).toUpperCase();
  } else if (type === 'OrgAccount' && organization !== null && orgName !== null) {
    valueToHash = orgName.slice(0, 1).toUpperCase();
  } else if (memberName !== null) {
    valueToHash = memberName.slice(0, 1).toUpperCase();
  }

  return <Image src={getAvatarComponent(valueToHash.toLowerCase())} alt={`${username}'s avatar`} {...props} />;
};

export default AccountAvatar;
