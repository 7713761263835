import { Icon, IconProps } from '@chakra-ui/react';

const ChevronIcon: React.FC<IconProps & { direction: 'right' | 'down' | 'left'; size: 'sm' | 'lg' }> = ({
  direction,
  size,
  ...props
}) => {
  switch (`${direction} ${size}`) {
    case 'right sm': {
      return (
        <Icon {...props} viewBox="0 0 12 12">
          <path
            fill="currentColor"
            d={` m4.452 10.753 4.37-4.372a.54.54 0 0 0 0-.763l-4.37-4.371a.54.54 0 0 0-.763 0l-.51.51a.54.54 0 0
            0-.001.763L6.642 6 3.178 9.48a.54.54 0 0 0 .001.763l.51.51a.54.54 0 0 0 .763 0Z`}
          />
        </Icon>
      );
    }
    case 'right lg': {
      return (
        <Icon {...props} viewBox="0 0 16 16">
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M5 14 11 8 5 2"
          />
        </Icon>
      );
    }
    case 'down sm': {
      return (
        <Icon {...props} viewBox="0 0 12 12">
          <path
            fill="currentColor"
            d={`M10.801 4.437 6.385 8.85A.545.545 0 0 1 5.614 8.85L1.199 4.437A.545.545 0 0 1 1.199 3.665L1.714
            3.151A.545.545 0 0 1 2.485 3.15L6 6.648 9.515 3.15A.545.545 0 0 1 10.286 3.151L10.801 3.665A.545.545
            0 0 1 10.801 4.437Z`}
          />
        </Icon>
      );
    }
    case 'down lg': {
      return (
        <Icon {...props} viewBox="0 0 16 16">
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M2 5 8 11 14 5"
          />
        </Icon>
      );
    }
    case 'left sm': {
      return (
        <Icon {...props} viewBox="0 0 12 12">
          <path
            fill="currentColor"
            d={`m7.548 10.753-4.37-4.372a.54.54 0 0 1 0-.763l4.37-4.371a.54.54 0 0 1 .763 0l.51.51a.54.54 0 0
            1.001.763L5.358 6 8.822 9.48a.54.54 0 0 1-.001.763l-.51.51a.54.54 0 0 1-.763 0Z`}
          />
        </Icon>
      );
    }
    case 'left lg': {
      return (
        <Icon {...props} viewBox="0 0 16 16">
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M11 14 5 8 11 2"
          />
        </Icon>
      );
    }
    default: {
      return <Icon {...props} />;
    }
  }
};

export default ChevronIcon;
