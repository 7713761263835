import { COLORS } from 'packages/constants';
import { ButtonLink, faArrowRight, Header, Icon, IconDefinition, Paragraph } from 'packages/elements';
import React from 'react';

export const Banner: React.FC<{
  title: string;
  titleIcon?: IconDefinition;
  content: string;
  ctaText?: string;
  ctaHref?: string;
}> = ({ title, titleIcon, content, ctaText, ctaHref }) => {
  return (
    <div className="border-2 border-purple-500 bg-neutral-1000 rounded-md p-6 flex flex-col gap-2">
      <Header type="h5" color={COLORS.Neutral[0]}>
        {titleIcon && <Icon icon={titleIcon} className="mr-1.5" color={COLORS.Purple[300]} />}
        {title}
      </Header>
      <div className="flex items-end gap-2">
        <Paragraph color={COLORS.Neutral[100]}>{content}</Paragraph>
        {ctaText && ctaHref && (
          <div className="min-w-[140px] text-center">
            <ButtonLink btnType="Transparent" href={ctaHref}>
              Learn More
              <Icon icon={faArrowRight} className="ml-1.5" />
            </ButtonLink>
          </div>
        )}
      </div>
    </div>
  );
};
