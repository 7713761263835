import debounce from 'lodash.debounce';
import { useEffect, useRef } from 'react';

function useEvent(fn: () => void, delay = 100) {
  const refs = useRef({ delay, fn });

  useEffect(() => {
    refs.current.fn();

    const handleEventAfter100ms = debounce(refs.current.fn, refs.current.delay);

    window.addEventListener('resize', handleEventAfter100ms);

    return () => {
      handleEventAfter100ms.cancel();

      window.removeEventListener('resize', handleEventAfter100ms);
    };
  }, []);
}

export default useEvent;
