import { ChangeEventHandler, FormEventHandler, MouseEventHandler, useEffect, useRef, useState } from 'react';

function useQueryForm({
  onSubmit,
  onClear,
  query = null,
}: {
  onSubmit: (value: string) => void;
  onClear?: () => void;
  query?: string | null;
}) {
  const [value, setValue] = useState(query || '');
  const previousQuery = useRef(query);

  const handleSubmit: FormEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    previousQuery.current = query;
    onSubmit(value);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    setValue(e.currentTarget.value);
  };

  const handleClear: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();

    setValue('');

    if (!onClear) {
      return;
    }

    onClear();
  };

  useEffect(() => {
    if (query) {
      if (query !== previousQuery.current) {
        setValue(query);
      }

      return;
    }

    setValue('');
  }, [query]);

  return { value, handleSubmit, handleChange, handleClear };
}

export default useQueryForm;
