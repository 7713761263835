import Link from 'next/link';
import { faExternalLink, Icon } from 'packages/elements';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export const Anchor: React.FC<
  { href: string; underlineOnHover?: boolean; external?: boolean; className?: string } & PropsWithChildren
> = ({ href, underlineOnHover = false, external = false, className = '', children }) => {
  const linkClass = twMerge('text-sm font-normal', underlineOnHover ? 'hover:underline' : 'underline', className);
  return (
    <Link href={href} target={external ? '_blank' : ''} className={linkClass}>
      {children}
      {external && <Icon className="ml-2" icon={faExternalLink} />}
    </Link>
  );
};
