import { Badge, faCircleStar, Icon } from 'packages/elements';
import { PropsWithChildren } from 'react';

export const OfficialBadge: React.FC = () => {
  return (
    <Badge variant="branded" className="border-transparent font-normal">
      <Icon className="mr-1" icon={faCircleStar} />
      Official
    </Badge>
  );
};

export const GenericBadge: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Badge variant="static" className="border-transparent py-0 px-1 rounded-sm font-normal">
      {children}
    </Badge>
  );
};
