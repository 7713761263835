import router from 'next/router';
import { COLORS } from 'packages/constants';
import { useMemo, useState } from 'react';
import {
  ColumnFiltersState,
  Columns,
  faX,
  Header,
  Icon,
  ReactTableBody,
  ReactTableHeader,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  Span,
  Table,
  TableHookOptions,
  TableRow,
  TData,
  useTableHook,
  VisuallyHidden,
} from 'upbound-frontend-elements';

import { useOrgs } from '@/contexts/organizations';
import { RegistryPackage } from '@/graphql/upbound-graphql';
import { Anchor, SearchFilterInputField } from '@/redesign';
import { ROUTE_PACKAGE_DETAILS } from '@/utils/constants/routes';
import { MARKETING_TIER_PRICING_URL } from '@/utils/constants/urls';
import { canAccessLTSVersions } from '@/utils/helpers/organizations';

type PackageVersionObj = {
  version: string;
  package: string;
  accessibleByUser: boolean;
};

const columns: Columns<PackageVersionObj> = [
  {
    accessorKey: 'version',
    header: 'Version',
    cell: info => {
      const versionObj = info.row.original;

      return (
        <>
          <Span textSize="Size4" className={!versionObj.accessibleByUser ? '!text-neutral-400' : ''}>
            {info.getValue()}
          </Span>
        </>
      );
    },
  },
  {
    accessorKey: 'package',
    header: 'Package',
    meta: {
      textAlign: 'right',
    },
    cell: info => {
      const versionObj = info.row.original;
      return versionObj.accessibleByUser ? (
        <Span textSize="Size4">{`${info.getValue()}:${versionObj.version}`}</Span>
      ) : (
        <Span textSize="Size4">
          <Anchor href={MARKETING_TIER_PRICING_URL} className="text-purple-500 hover:no-underline">
            Upgrade
          </Anchor>{' '}
          to access this version
        </Span>
      );
    },
  },
];

export const PackageVersionsDrawer: React.FC<{ onClose?: () => void; data: RegistryPackage }> = ({ onClose, data }) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const {
    orgState: { fetchStage },
    currentOrg,
  } = useOrgs();

  const orgsLoading = fetchStage === 'unloaded' || fetchStage === 'loading';

  const handleOnOpenChange = (isOpen: boolean) => {
    if (!isOpen && !!onClose) {
      onClose();
    }
  };

  const { versions: publicVersions, relatedRepository: privateRepo, repositoryId: publicRepoId } = data;

  const currentOrgIsOnPaidTier = !!currentOrg && canAccessLTSVersions(currentOrg);

  const privateVersions = useMemo(() => {
    return privateRepo?.versions || [];
  }, [privateRepo?.versions]);
  const privateRepoId = privateRepo?.repoKey || '';

  const privateRepoExists = !!privateRepoId && privateVersions.length > 0;

  const packageVersions: PackageVersionObj[] = useMemo(() => {
    if (!publicVersions?.length) {
      return [];
    }

    if (privateRepoExists) {
      // Private repos contain paid tier versions which means they are only accessible by paid users
      // If there is a public counterpart version, the free users can access that version

      return privateVersions.map(privateVersion => {
        return {
          version: privateVersion,
          package: publicRepoId,
          accessibleByUser: publicVersions.includes(privateVersion) || currentOrgIsOnPaidTier,
        };
      });
    }

    // No Private repo exists. All public versions should be accessible
    return publicVersions.map(publicVersion => {
      return {
        version: publicVersion,
        package: publicRepoId,
        accessibleByUser: true,
      };
    });
  }, [publicVersions, publicRepoId, currentOrgIsOnPaidTier, privateRepoExists, privateVersions]);

  const tableParams: TableHookOptions<PackageVersionObj> = {
    data: packageVersions,
    columns,
    state: { columnFilters },
    initialState: { sorting: [{ id: 'version', desc: true }] },
  };

  const { headerGroups, tableRows } = useTableHook(tableParams);

  // For loading skeleton
  const loadingColumns = columns.map((col, index) => `${col.id}-${index}`);

  return (
    <Sheet defaultOpen onOpenChange={handleOnOpenChange}>
      {/* eslint-disable-next-line jsx-a11y/aria-props */}
      <SheetContent className="z-[1001] p-0 !w-3/5 min-w-[600px]" aria-description="Package Version Drawer">
        {/* Implemented only for semantic/screen-readers purposes */}
        <VisuallyHidden.Root>
          <SheetHeader>
            <SheetTitle>Package Versions</SheetTitle>
            <SheetDescription>Displaying Package Version List</SheetDescription>
          </SheetHeader>
        </VisuallyHidden.Root>
        <div className=" flex flex-col overflow-y-auto bg-neutral-0 h-full">
          <div className="border-b border-neutral-200 p-6">
            <div className="flex flex-row justify-between">
              <Header type="h4">Versions ({packageVersions.length})</Header>
              <Icon
                fontSize={14}
                color={COLORS.Neutral[600]}
                icon={faX}
                className="hover:cursor-pointer"
                onClick={onClose}
              />
            </div>
          </div>
          <div className="p-6 flex flex-col gap-2">
            <SearchFilterInputField
              onSubmit={(value: string) => {
                setColumnFilters([{ id: 'version', value }]);
              }}
            />
            <Table>
              <ReactTableHeader headerGroups={headerGroups} sticky={false} />
              <ReactTableBody
                tableRows={tableRows}
                emptyResultsMsg="No Package Versions Available"
                isLoading={orgsLoading}
                loadingColumns={loadingColumns}
                emptyColSpan={loadingColumns.length}
              >
                {tableRows.map(row => {
                  const [packageAccount, packageName] = row.original.package.split('/');

                  return (
                    <TableRow
                      key={row.id}
                      hoverable={true}
                      onClick={() => {
                        !!onClose && onClose();

                        router.push({
                          ...ROUTE_PACKAGE_DETAILS,
                          query: {
                            packageType: data.packageType.toLowerCase() + 's',
                            packageAccount,
                            packageName,
                            packageVersion: row.original.version,
                          },
                        });
                      }}
                    >
                      {row.getVisibleCells().map(cell => (
                        <TData key={cell.id} cell={cell} className="text-sm" />
                      ))}
                    </TableRow>
                  );
                })}
              </ReactTableBody>
            </Table>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
