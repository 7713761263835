const ROUTE_ROOT_INDEX = {
  pathname: '/',
  query: {},
};

const ROUTE_PACKAGE_LIST = {
  pathname: '/[packageType]',
  query: {
    packageType: '',
  },
};

const ROUTE_PACKAGE_DETAILS = {
  pathname: ROUTE_PACKAGE_LIST.pathname + '/[packageAccount]/[packageName]/[packageVersion]',
  query: {
    ...ROUTE_PACKAGE_LIST.query,
    packageAccount: '',
    packageName: '',
    packageVersion: '',
  },
};

const ROUTE_COMPOSITION_DETAILS = {
  pathname: ROUTE_PACKAGE_DETAILS.pathname + '/compositions/[compositionName]',
  query: {
    ...ROUTE_PACKAGE_DETAILS.query,
    compositionName: '',
  },
};

const ROUTE_RESOURCE_DETAILS = {
  pathname: ROUTE_PACKAGE_DETAILS.pathname + '/resources/[resourceGroup]/[resourceKind]/[resourceVersion]',
  query: {
    ...ROUTE_PACKAGE_DETAILS.query,
    resourceGroup: '',
    resourceKind: '',
    resourceVersion: '',
  },
};

export {
  ROUTE_ROOT_INDEX,
  ROUTE_PACKAGE_LIST,
  ROUTE_PACKAGE_DETAILS,
  ROUTE_COMPOSITION_DETAILS,
  ROUTE_RESOURCE_DETAILS,
};
