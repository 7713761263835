import { Icon, IconProps } from '@chakra-ui/react';

const PlaceholderIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 65 65">
      <g fill="none" fillRule="evenodd" stroke="#DCDCDE">
        <rect width="64" height="64" x=".5" y=".5" fill="#F4F5F7" rx="15" />
        <circle cx="32.5" cy="32.5" r="8.979" />
        <circle cx="32.5" cy="32.5" r="21.167" />
        <path d="m4.74 4.74 55.52 55.52m-55.52 0L60.26 4.74" />
      </g>
    </Icon>
  );
};

export default PlaceholderIcon;
