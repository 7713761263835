import { Box, Button, Collapse, Flex, Link, Spinner, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { RefObject, useEffect, useRef, useState } from 'react';

import PackageSearchForm from '@/components/PackageSearchForm';
import RouteLink from '@/components/RouteLink';
import { useCurrentUser } from '@/contexts/currentUser';
import { RegistryPackageType } from '@/graphql/upbound-graphql';
import CloseIcon from '@/icons/CloseIcon';
import CubeIcon from '@/icons/CubeIcon';
import HamburgerIcon from '@/icons/HamburgerIcon';
import {
  accountsLoginUrl,
  accountsLogoutUrl,
  UPBOUND_DOCS_PROVIDERS_URL,
  UPBOUND_DOCS_PUBLISH_URL,
  UPBOUND_REGISTER_URL_A,
} from '@/utils/constants/urls';
import { clearParams, updateQueryParam, withQueryParams } from '@/utils/helpers/params';
import useHeight from '@/utils/hooks/useHeight';
import useParams from '@/utils/hooks/useParams';

import AccountPopover from './AccountPopover';
import UpboundLogoAnchor from './UpboundLogoAnchor';

const PageHeader: React.FC<{
  mobileRef?: RefObject<HTMLDivElement>;
  isSearchVisible?: boolean;
  packageType: RouteParams.PackageType | undefined;
}> = ({ mobileRef, isSearchVisible = true, packageType = 'providers' }) => {
  const { currentUser, fetchStage } = useCurrentUser();
  const router = useRouter();
  const params = useParams(router);
  const [{ isOpen }, setState] = useState({ isOpen: false });
  const localMobileRef = useRef<HTMLDivElement>(null);
  const headerHeight = useHeight(mobileRef || localMobileRef);
  const query = decodeURIComponent(params.get('query') || '');

  const onSubmit = (value: string) => {
    router.push(withQueryParams(`/${packageType}`, updateQueryParam(value, params)));
    setState({ isOpen: false });
  };

  const onClear = () => {
    router.push(withQueryParams(`/${packageType}`, clearParams(params)));
    setState({ isOpen: false });
  };

  useEffect(() => {
    if (!isOpen) {
      document.body.style.overflow = '';
      return;
    }

    document.body.style.overflow = 'hidden';
  }, [isOpen]);

  return (
    <Box width="100%" position={{ base: 'sticky', lg: 'static' }} top="0" zIndex={1000}>
      <Box backgroundColor="blue.1" width="100%">
        <Flex
          display={{ base: 'none', lg: 'flex' }}
          width="100%"
          maxWidth="1440px"
          height="80px"
          m="0 auto"
          p="19px 60px 21px 38px"
          alignItems="center"
          justifyContent="center"
        >
          <UpboundLogoAnchor variant="purple" size="lg" mr="auto" />
          <PackageSearchForm
            size="small"
            variant="desktop"
            visible={isSearchVisible}
            query={query}
            onSubmit={onSubmit}
            onClear={onClear}
            m="0 24px"
          />
          <RouteLink
            to="packageList"
            params={{ packageType: RegistryPackageType.Provider }}
            fontWeight={600}
            fontSize="16px"
            lineHeight="22px"
            color="white.0"
            prefetch={false}
            _hover={{ opacity: 0.7 }}
            ml="auto"
          >
            Browse
          </RouteLink>
          <Link
            href={UPBOUND_DOCS_PUBLISH_URL}
            isExternal={true}
            fontWeight={600}
            fontSize="16px"
            lineHeight="22px"
            color="white.0"
            _hover={{ opacity: 0.7 }}
            ml="32px"
          >
            Publish
          </Link>
          {(() => {
            switch (fetchStage) {
              case 'unloaded':
                return <Flex width="226.6px" height="40px" ml="32px" />;
              case 'loading':
                return (
                  <Flex width="226.6px" ml="32px" height="40px" alignItems="center" justifyContent="center">
                    <Spinner role="Spinner" color="white.0" size="lg" />
                  </Flex>
                );
              case 'failed':
                return (
                  <Flex alignItems="center" justifyContent="center" ml="32px">
                    <Button
                      as={Link}
                      href={accountsLoginUrl(router.asPath)}
                      variant="whiteOutline"
                      p="10px 18px"
                      borderRadius="24px"
                      fontWeight={900}
                      fontSize="14px"
                      lineHeight="16px"
                      _hover={{ backgroundColor: 'white.0', color: 'blue.3', textDecoration: 'none' }}
                    >
                      Sign In
                    </Button>
                    <Button
                      as={Link}
                      href={UPBOUND_REGISTER_URL_A}
                      variant="cornflowerFill"
                      p="10px 18px"
                      borderRadius="24px"
                      fontWeight={900}
                      fontSize="14px"
                      lineHeight="16px"
                      ml="16px"
                    >
                      Try for Free
                    </Button>
                  </Flex>
                );
              case 'succeeded':
                return <AccountPopover currentUser={currentUser} />;
            }
          })()}
        </Flex>
        <Flex
          display={{ base: 'flex', lg: 'none' }}
          ref={mobileRef || localMobileRef}
          position="relative"
          zIndex={200}
          alignItems="center"
          justifyContent="center"
          width="100%"
          p="22px 30px"
        >
          <UpboundLogoAnchor variant="purple" size="sm" mr="auto" />
          <Button
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={0}
            backgroundColor="transparent"
            border="0"
            size="none"
            onClick={() => setState({ isOpen: !isOpen })}
            _hover={{ backgroundColor: 'transparent' }}
          >
            {isOpen ? (
              <CloseIcon name="close" width="20px" height="20px" color="white.0" />
            ) : (
              <HamburgerIcon name="hamburger" width="20px" height="20px" color="white.0" />
            )}
          </Button>
        </Flex>
        <Collapse
          in={isOpen}
          animateOpacity={false}
          style={{
            position: 'fixed',
            top: `${headerHeight}px`,
            width: '100%',
          }}
          endingHeight={`calc(100% - ${headerHeight}px)`}
        >
          <Box backgroundColor="white.0" height="100%" width="100%" display={{ lg: 'none' }}>
            <PackageSearchForm size="small" variant="mobile" query={query} onSubmit={onSubmit} onClear={onClear} />
            <Flex flexDirection="column" width="100%" flexGrow={1} backgroundColor="white.0" p="35px 30px 35px 30px">
              {/*
                  TODO: Add mobile version
                  <AccountPopover />
                  */}
              <RouteLink
                to="packageList"
                params={{ packageType: RegistryPackageType.Provider }}
                onClick={() => setState({ isOpen: false })}
                as="span"
                display="flex"
                alignItems="center"
                fontWeight={600}
                fontSize="16px"
                lineHeight="22px"
                color="black.0"
                mb="21px"
                prefetch={false}
                _hover={{ opacity: 0.7 }}
              >
                <CubeIcon color="black.0" width="14px" height="14px" mr="6px" />
                Browse
              </RouteLink>
              <NextLink href={UPBOUND_DOCS_PROVIDERS_URL} passHref={true} legacyBehavior={true}>
                <Link
                  isExternal={true}
                  as="span"
                  display="flex"
                  alignItems="center"
                  fontWeight={600}
                  fontSize="16px"
                  lineHeight="22px"
                  color="black.0"
                  mb="22px"
                >
                  <Text
                    as="span"
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="11px"
                    color="white.0"
                    backgroundColor="black.0"
                    width="14px"
                    height="14px"
                    mr="6px"
                  >
                    +
                  </Text>
                  Publish
                </Link>
              </NextLink>
              {currentUser ? (
                <Button
                  as={Link}
                  href={accountsLogoutUrl(router.asPath)}
                  variant="blackOutline"
                  p="10px 18px"
                  borderRadius="24px"
                  fontWeight={900}
                  fontSize="14px"
                  lineHeight="16px"
                >
                  Sign Out
                </Button>
              ) : (
                <Button
                  as={Link}
                  href={accountsLoginUrl(router.asPath)}
                  variant="cornflowerFill"
                  p="10px 18px"
                  borderRadius="24px"
                  fontWeight={900}
                  fontSize="14px"
                  lineHeight="16px"
                >
                  Sign In
                </Button>
              )}
            </Flex>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default PageHeader;
