import { zodResolver } from '@hookform/resolvers/zod';
import { faMagnifyingGlass, Form, FormControl, FormField, FormItem, FormMessage, Icon, Input } from 'packages/elements';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const SearchFilterInputField: React.FC<{ onSubmit: (value: string) => void }> = ({ onSubmit }) => {
  const FormSchema = z.object({
    searchInput: z.string(),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    mode: 'onChange',
    resolver: zodResolver(FormSchema),
    defaultValues: {
      searchInput: '',
    },
  });

  return (
    <Form {...form}>
      <form
        onChange={form.handleSubmit((data: z.infer<typeof FormSchema>) => {
          onSubmit(data.searchInput);
        })}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        className="w-full flex flex-col grow"
      >
        <FormField
          control={form.control}
          name="searchInput"
          render={({ field }) => (
            <FormItem className="flex flex-row justify-center relative">
              <Icon fontSize={12} icon={faMagnifyingGlass} className="text-neutral-500 absolute left-3 top-2" />
              <FormControl className="!m-0 pl-8">
                <Input placeholder="Search..." {...field} className="bg-neutral-0 border-neutral-100 h-[34px]" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
};
