import { COLORS } from 'packages/constants';
import { Header, Span } from 'packages/elements';

import { Anchor } from '@/redesign';
import {
  MARKETING_ABOUT_URL,
  MARKETING_PRIVACY_URL,
  MARKETING_TERMS_URL,
  UPBOUND_CONTACT_URL,
  UPBOUND_DOCS_URL,
  UPBOUND_REGISTER_URL_A,
} from '@/utils/constants/urls';

import UpboundLogoAnchor from './UpboundLogoAnchor';

export const PageFooter: React.FC = () => {
  const columnClass = 'flex flex-col gap-3';

  return (
    <div className="flex flex-col md:flex-row justify-between w-full items-center p-8 border-t border-neutral-100 bg-neutral-0 gap-8 md:gap-4">
      <div className="text-left w-full md:w-auto">
        <div className={columnClass}>
          <div>
            <UpboundLogoAnchor variant="dark" />
          </div>
          <div>
            <Span textSize="Size4" color={COLORS.Neutral[600]}>
              Discover the building blocks for your internal cloud platform.
            </Span>
          </div>
          <div>
            <Span textSize="Size4" color={COLORS.Neutral[600]}>
              © 2024 Upbound, Inc.
            </Span>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-16">
        <div className={columnClass}>
          <div>
            <Header type="h5">Solutions</Header>
          </div>
          <div>
            <Anchor href="/providers" underlineOnHover={true}>
              Providers
            </Anchor>
          </div>
          <div>
            <Anchor href="/configurations" underlineOnHover={true}>
              Configurations
            </Anchor>
          </div>
        </div>
        <div className={columnClass}>
          <div>
            <Header type="h5">Learn</Header>
          </div>
          <div>
            <Anchor href={UPBOUND_DOCS_URL} underlineOnHover={true}>
              Documentation
            </Anchor>
          </div>
          <div>
            <Anchor href={UPBOUND_REGISTER_URL_A} underlineOnHover={true}>
              Try for Free
            </Anchor>
          </div>
        </div>
        <div className={columnClass}>
          <div>
            <Header type="h5">Company</Header>
          </div>
          <div>
            <Anchor href={MARKETING_ABOUT_URL} underlineOnHover={true}>
              About
            </Anchor>
          </div>
          <div>
            <Anchor href={UPBOUND_CONTACT_URL} underlineOnHover={true}>
              Contact Us
            </Anchor>
          </div>
        </div>
        <div className={columnClass}>
          <div>
            <Header type="h5">More</Header>
          </div>
          <div>
            <Anchor href={MARKETING_PRIVACY_URL} underlineOnHover={true}>
              Privacy Policy
            </Anchor>
          </div>
          <div>
            <Anchor href={MARKETING_TERMS_URL} underlineOnHover={true}>
              Terms & Conditions
            </Anchor>
          </div>
        </div>
      </div>
    </div>
  );
};
