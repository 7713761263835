import { Icon, IconProps } from '@chakra-ui/react';

const SignOutIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 14 14">
      <path
        fill="currentColor"
        d="M3.723 14.125h1.75a.874.874 0 1 0 0-1.75h-1.75a.875.875 0 0 1-.875-.875v-7c0-.483.392-.875.875-.875h1.75a.875.875 0 1 0 0-1.75h-1.75A2.625 2.625 0 0 0 1.098 4.5v7a2.625 2.625 0 0 0 2.625 2.625zm11.178-6.604-3.94-3.718a.658.658 0 0 0-1.108.477l-.002 1.969h-3.5a.875.875 0 0 0-.876.875v1.75c0 .483.392 .875.876 .875h3.5l.002 1.944a.657.657 0 0 0 1.108.477l3.94-3.719c.263-.221.263-.68 0-.93z"
      />
    </Icon>
  );
};

export default SignOutIcon;
