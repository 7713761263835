import { capitalCase } from 'change-case';

import { CurrentOrg } from '@/contexts/organizations';
import {
  RegistryPackage,
  RegistryPackageType,
  RegistrySearchResult,
  SubscriptionTier,
} from '@/graphql/upbound-graphql';
import { PartialRegistryPackage } from '@/redesign/types';
import { CONFIG_REGISTRY_HOST } from '@/utils/constants/config';
import { canAccessLTSVersions } from '@/utils/helpers/organizations';

function getIsFamilyProvider(packageData: RegistryPackage | RegistrySearchResult) {
  const { repositoryId, familyRepositoryId } = packageData;
  return !!familyRepositoryId && repositoryId === familyRepositoryId;
}

function getBaseCodeBlock(data: PartialRegistryPackage) {
  const packageUrl = `${CONFIG_REGISTRY_HOST}/${data.account}/${data.name}:${data.version}`;
  const apiVersion =
    data.packageType === RegistryPackageType.Function ? 'pkg.crossplane.io/v1beta1' : 'pkg.crossplane.io/v1';

  return `apiVersion: ${apiVersion}
kind: ${capitalCase(data.packageType)}
metadata:
  name: ${data.name}
spec:
  package: ${packageUrl}`;
}

function getPrivateCodeBlock() {
  return `
  packagePullSecrets:
    - name: package-pull-secret`;
}

function getCodeBlock(data: PartialRegistryPackage) {
  const base = getBaseCodeBlock(data);
  if (data.public === false) {
    return base + getPrivateCodeBlock();
  }

  return base;
}

function getUserCanAccessPackage(currentOrg: CurrentOrg, repoSubscriptionTier: SubscriptionTier) {
  if (repoSubscriptionTier === SubscriptionTier.Free) {
    return true;
  }

  if (!currentOrg) {
    return false;
  }

  return canAccessLTSVersions(currentOrg);
}

export { getIsFamilyProvider, getCodeBlock, getUserCanAccessPackage };
