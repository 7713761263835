import { faChevronRight, Icon } from 'packages/elements';
import React from 'react';

import RouteLink from '@/components/RouteLink';
import { RegistryPackage } from '@/graphql/upbound-graphql';
import { Anchor } from '@/redesign';

const Chevron: React.FC = () => {
  return <Icon icon={faChevronRight} fontSize="0.7em" />;
};

enum PackageTypeSlug {
  CONFIGURATION = 'configurations',
  FUNCTION = 'functions',
  PROVIDER = 'providers',
}

export const Breadcrumbs: React.FC<{ data: RegistryPackage }> = ({ data }) => {
  const isInFamilyProvider = !!data.familyRepositoryId && data.familyRepositoryId !== data.repositoryId;
  const familyRepositoryName = data.familyRepositoryId ? data.familyRepositoryId.split('/')[1] : '';
  const packageName = data.repositoryId ? data.repositoryId.split('/')[1] : '';

  const packageTypeSlug = PackageTypeSlug[data.packageType];

  return (
    <div className="flex gap-1 items-baseline">
      <Anchor href="/" underlineOnHover={true} className="text-sm">
        Home
      </Anchor>

      <Chevron />

      {packageTypeSlug && (
        <>
          <Anchor href={`/${packageTypeSlug}`} underlineOnHover={true} className="capitalize">
            {packageTypeSlug}
          </Anchor>

          <Chevron />
        </>
      )}

      {isInFamilyProvider && familyRepositoryName && (
        <>
          <RouteLink
            to="packageDetails"
            params={{
              packageType: data.packageType,
              packageOwner: data.account,
              packageName: familyRepositoryName,
              packageVersion: 'latest',
            }}
            display="block"
            prefetch={false}
            _hover={{ textDecoration: 'underline' }}
            fontSize="14px"
            color="blackAlpha.900"
          >
            {familyRepositoryName}
          </RouteLink>
          <Chevron />
        </>
      )}

      <RouteLink
        to="packageDetails"
        params={{
          packageType: data.packageType,
          packageOwner: data.account,
          packageName,
          packageVersion: 'latest',
        }}
        display="block"
        prefetch={false}
        _hover={{ textDecoration: 'underline' }}
        fontSize="14px"
        color="blackAlpha.900"
      >
        {packageName}
      </RouteLink>
    </div>
  );
};
