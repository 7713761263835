/* eslint-disable simple-import-sort/imports */
import Prism from 'prismjs';

import 'prismjs/themes/prism.min.css';
import 'prismjs/components/prism-yaml.min.js';
import 'prismjs/components/prism-bash.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.min.css';
/* eslint-enable */

import { chakra, Code, HTMLChakraProps } from '@chakra-ui/react';
import { CSSProperties, PropsWithChildren, useEffect, useRef } from 'react';
import ClipboardCopy from '@/components/ClipboardCopy';
import CopyIcon from '@/icons/CopyIcon';

const Pre = chakra('pre', {
  baseStyle: {
    '&&&': {
      backgroundColor: 'white.8',
      border: '1px solid',
      borderColor: 'white.4',
      position: 'relative',
      padding: '22px 27px 26px 30px',
      '&.line-numbers': {
        padding: '22px 27px 26px 50px',
      },
      textWrap: 'wrap',
    },
  },
});

const CopyToClipboard: React.FC<
  {
    plugins: Array<'copy-to-clipboard' | 'line-numbers'>;
    children: string | undefined;
  } & HTMLChakraProps<'a'>
> = ({ plugins, children = '', ...props }) => {
  if (!plugins.includes('copy-to-clipboard')) {
    return null;
  }

  return (
    <ClipboardCopy href={children} display="flex" ml="15px" position="absolute" top="18px" right="18px" {...props}>
      <CopyIcon color="black.0" width="16px" height="16px" />
    </ClipboardCopy>
  );
};

const PrismHighlighter: React.FC<
  PropsWithChildren<
    {
      plugins?: Array<'copy-to-clipboard' | 'line-numbers'>;
      language?: string;
      codeStyle?: CSSProperties;
      copyIconProps?: HTMLChakraProps<'a'>;
    } & HTMLChakraProps<'pre'>
  >
> = ({ children, plugins = [], language = 'markdown', codeStyle, copyIconProps, ...props }) => {
  const preRef = useRef<HTMLPreElement>(null);
  const codeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!codeRef.current || !preRef.current) {
      return;
    }

    if (plugins) {
      plugins.forEach(plugin => {
        preRef.current?.classList.add(plugin);
      });
    }

    codeRef.current.classList.add(`language-${language}`);

    Prism.highlightElement(codeRef.current);
  }, [preRef, codeRef, language, plugins]);

  return (
    <Pre ref={preRef} {...props}>
      <Code ref={codeRef} style={codeStyle}>
        {children}
      </Code>
      <CopyToClipboard plugins={plugins} {...copyIconProps}>
        {children?.toString() || ''}
      </CopyToClipboard>
    </Pre>
  );
};

export default PrismHighlighter;
