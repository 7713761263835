import { capitalCase } from 'change-case';
import Head from 'next/head';
import { useRouter } from 'next/router';

import * as favicons from '@/assets/favicons';
import { CONFIG_SENTRY_ENVIRONMENT } from '@/utils/constants/config';
import SENTRY_ENVIRONMENTS from '@/utils/constants/sentry';

const environment = capitalCase(CONFIG_SENTRY_ENVIRONMENT);

const { favicon270 } = (() => {
  switch (CONFIG_SENTRY_ENVIRONMENT) {
    case SENTRY_ENVIRONMENTS.Production:
      return favicons.production;
    case SENTRY_ENVIRONMENTS.Development:
    default:
      return favicons.development;
  }
})();

const registryName = 'Upbound Marketplace';
const registryDomain = 'https://marketplace.upbound.io';
const registryDescription =
  'Upbound Marketplace is where developers discover the building blocks for their internal cloud platform.';

const PageTitle: React.FC<{ title?: string; description?: string | null; image?: string | null }> = ({
  title,
  description,
  image,
}) => {
  const router = useRouter();
  const url = registryDomain + router.asPath;
  title = title ? `${title} | ${registryName}` : registryName;

  if (CONFIG_SENTRY_ENVIRONMENT !== SENTRY_ENVIRONMENTS.Production) {
    title = `${title} | ${environment}`;
  }

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description || registryDescription} />
      <meta name="application-name" content={`${registryName}`} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:site" content="@upbound_io" />
      <meta name="twitter:description" content={description || registryDescription} />
      <meta name="twitter:image" content={image || favicon270} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={`${url}`} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description || registryDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={`Upbound Marketplace`} />
      <meta property="og:image" content={image || favicon270} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:alt" content="Upbound logo" />
      <meta property="og:image:width" content="270" />
      <meta property="og:image:height" content="270" />
      <meta property="og:url" content={`${url}`} />
    </Head>
  );
};

export default PageTitle;
