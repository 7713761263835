import { Icon, IconProps } from '@chakra-ui/react';

const CloseIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d={`M19.538 2.532a1.325 1.325 0 0 1 1.873.008c.521.521.524 1.359.009 1.875l-7.538
        7.537 7.538 7.538c.478.478.509 1.235.095 1.758l-.104.116c-.52.52-1.358.523-1.873.009L12 13.833l-7.538
        7.538a1.324 1.324 0 0 1-1.757.097l-.116-.104a1.325 1.325 0 0 1-.009-1.875l7.537-7.539-7.538-7.535a1.324
        1.324 0 0 1-.094-1.759l.104-.116a1.325 1.325 0 0 1 1.873-.008L12 10.069Z`}
      />
    </Icon>
  );
};

export default CloseIcon;
