import { Icon, IconProps } from '@chakra-ui/react';

const CopyIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path
        fill="currentColor"
        // eslint-disable-next-line max-len
        d={`m10.784 13.569h-4.873c-.896 0-1.624-.729-1.624-1.624v-8.585h-2.088c-.384 0-.696.312-.696.696v10.673c0 .384.312 .696.696 .696h7.889c.384 0 .696-.312.696-.696zm0-9.977v-3.016h-4.873c-.384 0-.696.312-.696.696v10.673c0 .384.312 .696.696 .696h7.889c.384 0 .696-.312.696-.696v-7.657h-3.016c-.383 0-.696-.313-.696-.696zm3.509-.9-1.913-1.913c-.126-.126-.3-.204-.492-.204h-.176v2.784h2.784v-.176c0-.192-.078-.366-.204-.492z`}
      />
    </Icon>
  );
};

export default CopyIcon;
