import { RefObject, useState } from 'react';

import useEvent from './useEvent';

function useHeight<T extends HTMLElement>(ref: RefObject<T>) {
  const [height, setHeight] = useState(0);

  const handleResize = () => {
    if (!ref.current || ref.current.scrollHeight === height) {
      return;
    }

    setHeight(ref.current.scrollHeight);
  };

  useEvent(handleResize);

  return height;
}

export default useHeight;
