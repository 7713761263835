function isStatus(r: unknown): r is { status: number } {
  if (typeof r === 'object' && r !== null) {
    return 'status' in r;
  }

  return false;
}

function getStatus(r: unknown): number | undefined {
  if (!isStatus(r)) {
    return;
  }

  return r.status;
}

export { isStatus, getStatus };
