import { Icon, IconProps } from '@chakra-ui/react';

const HamburgerIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d={`M21.038 18.648C21.587 18.648 22.032 19.141 22.032 19.748 22.032 20.356 21.587 20.848 21.038
        20.848H2.962C2.413 20.848 1.968 20.356 1.968 19.748 1.968 19.141 2.413 18.648 2.962 18.648ZM21.038
        10.9C21.587 10.9 22.032 11.393 22.032 12 22.032 12.608 21.587 13.1 21.038 13.1H2.962C2.413 13.1 1.968
        12.608 1.968 12 1.968 11.393 2.413 10.9 2.962 10.9ZM21.038 3.152C21.587 3.152 22.032 3.644 22.032 4.252
        22.032 4.859 21.587 5.352 21.038 5.352H2.962C2.413 5.352 1.968 4.859 1.968 4.252 1.968 3.644 2.413 3.152
        2.962 3.152Z`}
      />
    </Icon>
  );
};

export default HamburgerIcon;
