import { ErrorResponse } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

import { getStatus } from '@/utils/guards/status';

import { accountsLoginUrl } from '../constants/urls';

function notFound(): { notFound: true } {
  return { notFound: true };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function props<Q extends GetServerSidePropsContext, P extends { [key: string]: any }>(
  c: Q,
  p: P,
): GetServerSidePropsResult<P> {
  return {
    props: { ...p, params: JSON.parse(JSON.stringify(c.params || {})) },
  };
}

function redirect(destination: string, permanent = false) {
  return {
    redirect: {
      permanent,
      destination,
    },
  };
}

function apolloErrors(graphQLErrors?: ReadonlyArray<GraphQLError>, url?: string) {
  if (graphQLErrors?.some(gqlError => getStatus(gqlError?.extensions?.response) === 401)) {
    return redirect(accountsLoginUrl(url));
  } else if (graphQLErrors?.some(gqlError => getStatus(gqlError?.extensions?.response) === 404)) {
    return redirect('/404');
  }

  return redirect('/500');
}

function isErrorResponse(unknownObject: unknown): unknownObject is ErrorResponse {
  const requiredKeys = ['graphQLErrors', 'networkError'];

  if (typeof unknownObject !== 'object') {
    return false;
  }

  for (const unknownKey in unknownObject) {
    if (requiredKeys.includes(unknownKey)) {
      return true;
    }
  }

  return false;
}

function tryCatch(error: unknown, url?: string) {
  if (!isErrorResponse(error)) {
    return redirect('/500');
  }

  return apolloErrors(error.graphQLErrors, url);
}

export { apolloErrors, notFound, props, redirect, tryCatch };
