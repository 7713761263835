import { Flex, FlexProps } from '@chakra-ui/react';

const PageContent: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex flexDirection="column" flexGrow={1} flexShrink={1} id="page-container" backgroundColor="white.0" {...props}>
      {children}
    </Flex>
  );
};

export default PageContent;
