const routeParams = [
  'packageType',
  'packageAccount',
  'packageName',
  'packageVersion',
  'compositionName',
  'compositionXrdGroup',
  'compositionXrdKind',
  'resourceGroup',
  'resourceKind',
  'resourceVersion',
  'pageName',
];

enum FilterQueryType {
  Query = 'query',
  Visbility = 'visibility',
  CollapseFamilies = 'collapseFamilies',
  Tab = 'tab',
  Tier = 'tier',
  Starred = 'starred',
  Publisher = 'publisher',
  Page = 'page',
  Resources = 'resources',
}

function withoutRouteParams(params: URLSearchParams) {
  routeParams.forEach(routeParam => params.delete(routeParam));
  return params;
}

function withQueryParams(path: string, params: URLSearchParams) {
  const paramsString = withoutRouteParams(params).toString();

  if (!paramsString) {
    return `${path}`;
  }

  return `${path}?${paramsString}`;
}

function updateQueryParam(value: string | undefined, params: URLSearchParams) {
  if (value && value !== '') {
    params.set(FilterQueryType.Query, encodeURIComponent(value));
    params.delete(FilterQueryType.Page);
  } else {
    params.delete(FilterQueryType.Query);
    params.delete(FilterQueryType.Tab);
    params.delete(FilterQueryType.Page);
  }

  return withoutRouteParams(params);
}

function updateTabParam(value: string | undefined, params: URLSearchParams) {
  if (value && value !== '') {
    params.set(FilterQueryType.Tab, encodeURIComponent(value));
  } else {
    params.delete(FilterQueryType.Tab);
    params.delete(FilterQueryType.Query);
  }

  return withoutRouteParams(params);
}

function updateVisibilityParam(value: string | undefined, params: URLSearchParams) {
  if (value !== 'all' && value !== 'private' && value !== 'public' && value !== undefined) {
    return params;
  }

  if (value === params.get(FilterQueryType.Visbility)) {
    return params;
  }

  if (!value || value === 'all') {
    params.delete(FilterQueryType.Visbility);
  } else {
    params.set(FilterQueryType.Visbility, value);
    params.delete(FilterQueryType.Page);
  }

  return withoutRouteParams(params);
}

function updateTierParam(value: string | undefined, params: URLSearchParams) {
  if (value !== 'all' && value !== 'official' && value !== 'community' && value !== 'partner' && value !== undefined) {
    return params;
  }

  if (value === params.get(FilterQueryType.Tier)) {
    return params;
  }

  if (!value || value === 'all') {
    params.delete(FilterQueryType.Tier);
  } else {
    params.set(FilterQueryType.Tier, value);
    params.delete(FilterQueryType.Page);
  }

  return withoutRouteParams(params);
}

function updateStarredParam(value: boolean, params: URLSearchParams) {
  if (!value) {
    params.delete(FilterQueryType.Starred);
  } else {
    params.set(FilterQueryType.Starred, 'true');
    params.delete(FilterQueryType.Page);
  }
  return withoutRouteParams(params);
}

function updateCollapseFamiliesParam(value: boolean, params: URLSearchParams) {
  params.set(FilterQueryType.CollapseFamilies, `${value}`);
  params.delete(FilterQueryType.Page);

  return withoutRouteParams(params);
}

function isCollapseFamiliesFilterApplied(params: URLSearchParams) {
  if (params.get(FilterQueryType.Query)) {
    // If search query applied, only collapse family providers if filter is explicitly checked
    return params.get(FilterQueryType.CollapseFamilies) === 'true';
  } else {
    // If no search query applied, default to collapsing family providers (query will be null unless explicitly checked)
    return params.get(FilterQueryType.CollapseFamilies) !== 'false';
  }
}

function updatePublisherParam(value: string, params: URLSearchParams) {
  if (value === params.get(FilterQueryType.Publisher)) {
    return params;
  }

  if (!value || value === 'all') {
    params.delete(FilterQueryType.Publisher);
  } else {
    params.set(FilterQueryType.Publisher, value);
    params.delete(FilterQueryType.Page);
  }

  return withoutRouteParams(params);
}

function updateInPackageResourcesParam(value: boolean, params: URLSearchParams) {
  params.set(FilterQueryType.Resources, `${value}`);
  params.delete(FilterQueryType.Page);

  return withoutRouteParams(params);
}

function clearParams(params: URLSearchParams) {
  Object.values(FilterQueryType).forEach(param => {
    params.delete(param);
  });
  return params;
}

export {
  FilterQueryType,
  withQueryParams,
  updateQueryParam,
  updateVisibilityParam,
  updateStarredParam,
  updateTierParam,
  updateTabParam,
  clearParams,
  updatePublisherParam,
  updateCollapseFamiliesParam,
  isCollapseFamiliesFilterApplied,
  updateInPackageResourcesParam,
};
