import { Link, LinkProps, Tooltip, useClipboard } from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { forwardRef, Ref, useState } from 'react';

const ClipboardCopy = forwardRef(
  ({ href, children, ...props }: LinkProps & Required<Pick<LinkProps, 'href'>>, ref: Ref<HTMLAnchorElement>) => {
    const { onCopy } = useClipboard(href);
    const [isOpen, setOpen] = useState(false);
    const closeAfter1000ms = debounce(() => setOpen(false), 1000);

    return (
      <Tooltip label="Copied!" defaultIsOpen={false} isOpen={isOpen} placement="right">
        <Link
          onClick={() => {
            setOpen(true);
            closeAfter1000ms();
            onCopy();
          }}
          {...props}
          ref={ref}
        >
          {children}
        </Link>
      </Tooltip>
    );
  },
);

ClipboardCopy.displayName = 'ClipboardCopy';

export default ClipboardCopy;
