import { useRouter } from 'next/router';
import { COLORS } from 'packages/constants';
import {
  Button,
  faStar,
  Icon,
  Paragraph,
  Span,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'packages/elements';
import { useState } from 'react';

import { useCurrentUser } from '@/contexts/currentUser';
import { RegistryPackage, useUpdateUserSettingsMutation } from '@/graphql/upbound-graphql';
import { accountsLoginUrl } from '@/utils/constants/urls';
import { tryCatch } from '@/utils/helpers/routing';

export const StarPackageButton: React.FC<{ data: RegistryPackage }> = ({ data }) => {
  const { currentUser } = useCurrentUser();
  const router = useRouter();
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const [isStarred, setIsStarred] = useState<boolean>(!!data.userSettings?.starred);
  const [isStarDisabled, setIsStarDisabled] = useState<boolean>(false);

  const tooltip = !currentUser ? 'You must be signed in to star a repository' : isStarred ? 'Starred' : 'Star';

  const onStarClick = () => {
    if (isStarDisabled) {
      return;
    }

    if (!currentUser) {
      router.push(accountsLoginUrl(router.asPath));
    } else {
      setIsStarDisabled(true);

      updateUserSettings({
        variables: {
          payload: {
            account: data.account,
            name: data.name,
            starred: !isStarred,
          },
        },
      })
        .then(() => {
          setIsStarred(!isStarred);
        })
        .catch(error => tryCatch(error))
        .finally(() => {
          setIsStarDisabled(false);
        });
    }
  };

  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button btnType="Secondary" onClick={onStarClick} disabled={isStarDisabled}>
            <Icon icon={faStar} className="mr-1.5" color={isStarred ? COLORS.Yellow[400] : COLORS.Neutral[800]} />
            <Span textSize="Size5" className="whitespace-nowrap	">
              {isStarred ? 'Starred' : 'Star Package'}
            </Span>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="bottom">
          <Paragraph className="!text-xs !text-neutral-0">{tooltip}</Paragraph>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
