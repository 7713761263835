import { Icon, IconProps } from '@chakra-ui/react';

const UpboundLogoIcon: React.FC<IconProps> = props => {
  return (
    <Icon {...props} viewBox="0 0 103 25">
      <path
        fill="currentColor"
        d={`m72.891 9.557-.05 5.281c0 4.804-4.622 5.778-6.23 5.778-3.346
        0-6.172-2.039-6.172-5.778V9.557c0-1.169.675-1.869 1.764-1.869 1.116 0 1.791.7 1.791 1.869v4.881c0
        1.894.956 2.933 2.617 2.933 1.764 0 2.726-.883 2.726-2.933V9.557c0-1.169.675-1.869 1.791-1.869
        1.089 0 1.763.7 1.763 1.869m-58.595.232-.05 5.282c0 4.803-4.622 5.777-6.23 5.777-3.347
        0-6.171-2.039-6.171-5.777V9.789c0-1.168.674-1.87 1.764-1.87 1.115 0 1.79.702 1.79 1.87v4.881c0
        1.894.957 2.932 2.617 2.932 1.764 0 2.727-.882 2.727-2.932V9.789c0-1.168.674-1.87 1.79-1.87 1.089 0
        1.763.702 1.763 1.87m25.985 4.285c0-1.973-1.271-3.297-3.087-3.297-1.842 0-3.165 1.376-3.165 3.297 0
        1.921 1.323 3.297 3.165 3.297 1.816 0 3.087-1.351 3.087-3.297m3.632 0c0 3.971-2.387 6.542-5.993
        6.542-1.66 0-2.983-.571-3.865-1.609h-.051c-.156.907-.805 1.453-1.739 1.453-1.115
        0-1.79-.727-1.79-1.895V3.871c0-1.168.675-1.895 1.79-1.895 1.09 0 1.764.727 1.764
        1.895v5.244h.052c.856-1.013 2.179-1.583 3.839-1.583 3.606 0 5.993 2.57 5.993 6.542m8.25 3.297c1.868 0
        3.139-1.351 3.139-3.297 0-1.973-1.271-3.297-3.139-3.297-1.842 0-3.113 1.324-3.113 3.297 0 1.946 1.271
        3.297 3.113 3.297m0-9.839c4.021 0 6.771 2.647 6.771 6.542 0 3.867-2.75 6.542-6.771 6.542-3.995
        0-6.745-2.675-6.745-6.542 0-3.895 2.75-6.542 6.745-6.542m34.807 5.797v5.27c0 1.168-.675 1.896-1.79
        1.896-1.089 0-1.764-.728-1.764-1.896v-4.854c0-1.922-.882-2.935-2.595-2.935-1.79 0-2.827 1.22-2.827
        3.272v4.517c0 1.168-.674 1.896-1.79 1.896-1.089 0-1.764-.728-1.764-1.896V9.616c0-1.168.675-1.895
        1.764-1.895.934 0 1.583.519 1.764 1.402h.052c.856-1.013 2.101-1.558 3.736-1.558 3.398 0 5.214 2.025
        5.214 5.764m11.389.778c0-1.921-1.323-3.297-3.165-3.297-1.816 0-3.087 1.324-3.087 3.297 0 1.948 1.271
        3.297 3.087 3.297 1.842 0 3.165-1.376 3.165-3.297m3.554-10.203v14.695c0 1.168-.674 1.896-1.79
        1.896-.934 0-1.583-.546-1.738-1.455h-.052c-.882 1.039-2.205 1.609-3.865 1.609-3.607
        0-5.993-2.569-5.993-6.542s2.386-6.542 5.993-6.542c1.66 0 2.983.572 3.839
        1.584h.052V3.904c0-1.167.674-1.894 1.764-1.894 1.116 0 1.79.727 1.79 1.894M25.341
        14c0-1.89-1.219-3.159-2.959-3.159-1.764 0-3.033 1.318-3.033 3.159 0 1.841 1.269 3.16 3.033 3.16 1.74
        0 2.959-1.293 2.959-3.16m3.48 0c0 3.806-2.288 6.27-5.742 6.27-1.567
        0-2.835-.548-3.68-1.493h-.05v4.155c0 1.119-.647 1.816-1.715 1.816-1.045
        0-1.691-.697-1.691-1.816V9.671c0-1.119.646-1.791 1.691-1.791.919 0 1.541.498 1.691 1.368h.049c.846-.969
        2.113-1.517 3.705-1.517 3.454 0 5.742 2.463 5.742 6.269M6.247 2.834c0 1.425-1.155 2.581-2.58
        2.581s-2.58-1.156-2.58-2.581c0-1.426 1.155-2.582 2.58-2.582s2.58 1.156 2.58 2.582`}
      />
    </Icon>
  );
};

export default UpboundLogoIcon;
