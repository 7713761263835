import {
  Box,
  Divider,
  Flex,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import { capitalCase } from 'change-case';
import { useRouter } from 'next/router';

import { useOrgs } from '@/contexts/organizations';
import { GetCurrentUserQuery } from '@/graphql/upbound-graphql';
import ChevronIcon from '@/icons/ChevronIcon';
import SettingsIcon from '@/icons/SettingsIcon';
import SignOutIcon from '@/icons/SignOutIcon';
import { accountsLogoutUrl, accountsSettingsUrl } from '@/utils/constants/urls';

import RouteLink from '../RouteLink';
import AccountAvatar from './AccountAvatar';

const AccountPopover: React.FC<{ currentUser: GetCurrentUserQuery['currentUser'] | undefined }> = ({ currentUser }) => {
  const router = useRouter();
  const {
    orgState: { orgs },
  } = useOrgs();

  return (
    <Popover placement="bottom-end" isLazy>
      <PopoverTrigger>
        <Link display="flex" alignItems="center" justifyContent="flex-end" ml="32px" height="40px">
          <AccountAvatar width="26px" height="26px" type="UserAccount" user={currentUser} />
          <Text
            as="span"
            color="white.0"
            ml="8px"
            fontWeight="bold"
            fontSize="16px"
            lineHeight="22px"
            noOfLines={1}
            maxWidth="100%"
          >
            {currentUser?.username}
          </Text>
          <ChevronIcon direction="down" size="sm" color="white.0" width="14px" height="14px" ml="8px" />
        </Link>
      </PopoverTrigger>
      <Portal>
        <PopoverContent mt="4px">
          <PopoverBody p="14px 16px 13px 17px" width="200px">
            <Text as="span" fontWeight="bold" color="grey.0" noOfLines={1} fontSize="14px" lineHeight="20px" mb="2px">
              {capitalCase(`${currentUser?.firstName} ${currentUser?.lastName}`)}
            </Text>
            <Text as="span" fontSize="14px" lineHeight="20px" color="grey.0" mb="15px" noOfLines={1}>
              {currentUser?.email}
            </Text>
            <Link
              href={accountsSettingsUrl(router.asPath)}
              color="grey.0"
              fontSize="14px"
              lineHeight="20px"
              mb="4px"
              display="flex"
              alignItems="center"
            >
              <SettingsIcon color="grey.0" mr="8px" />
              Account Settings
            </Link>
            <Link
              href={accountsLogoutUrl(router.asPath)}
              color="grey.0"
              fontSize="14px"
              lineHeight="20px"
              display="flex"
              alignItems="center"
              onClick={() => {
                if (typeof window.Intercom !== 'function') {
                  return;
                }

                window.Intercom('shutdown');
              }}
            >
              <SignOutIcon color="grey.0" mr="8px" />
              Sign Out
            </Link>
          </PopoverBody>
          {orgs.length > 0 && (
            <>
              <Divider my="7px" />
              <PopoverBody p="13px 16px 13px 17px" width="200px">
                <Text as="span" fontWeight="bold" color="black.0" noOfLines={1} fontSize="14px" lineHeight="20px">
                  Manage
                </Text>
                {orgs.map((o, i) => {
                  return (
                    <RouteLink to={'accountPage'} params={{ account: o.organization.name }} mt="15px" key={i}>
                      <Flex width="100%" alignItems="center">
                        <AccountAvatar width="26px" height="26px" type={o.__typename} organization={o.organization} />
                        <Box pl="6px">{o.organization.displayName}</Box>
                      </Flex>
                    </RouteLink>
                  );
                })}
              </PopoverBody>
            </>
          )}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default AccountPopover;
